
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code1 } from "./data";

export default defineComponent({
  name: "basic-usage",
  data() {
    return {
      value1: 0,
      value2: 50,
      value3: 36,
      value4: 48,
      value5: 42
    };
  },
  components: {
    CodeHighlighter
  },
  methods: {
    formatTooltip(val) {
      return val / 100;
    }
  },
  setup() {
    return {
      code1
    };
  }
});
